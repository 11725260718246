<template>
  <div aut-applications-page>
    <LoaderContent :value="!currentContext" />
    <AddPageDialog
      v-if="addPageDialogFlag"
      :contextObj="currentContext"
      @close="addPageDialogFlag = false"
    />
    <Page
      v-if="flowDetails"
      :page="flowDetails"
      :context="currentContext"
      :access_control="access_control"
    />
  </div>
</template>
<script>
import { createContext, clone } from "@/util";
import { systemPageMixin } from "@/components/mixin";
import formListPage from "./form_list_page.js";
const debug = require("debug")("atman.components.forms_list");
debug(`forms_list`);

export default {
  name: "FormsList",
  mixins: [systemPageMixin],
  components: {
    AddPageDialog: () => import("@/components/editor/AddPageDialog"),
  },
  data() {
    return {
      addPageDialogFlag: false,
    };
  },
  mounted() {
    this.url = "/forms?page=home";
    this.currentContext = createContext(this.url);
    this.flowDetails = clone(formListPage);
  },
};
</script>
